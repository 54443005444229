<script setup lang="ts">
const mainLinks = [
  {
    name: 'Home',
    route: { name: 'home' },
    icon: 'solar:home-angle-linear',
    iconActive: 'solar:home-angle-bold',
  },
  {
    name: 'Explore',
    route: { name: 'explore' },
    icon: 'solar:compass-square-linear',
    iconActive: 'solar:compass-square-bold',
  },
]

const secondaryLinks = [
  {
    name: 'Religious',
    route: { name: 'radio-genres-genre', params: { genre: 'Religious' } },
    icon: 'ph:hands-praying',
    iconActive: 'fluent:sport-24-filled',
  },
  {
    name: 'Music',
    route: { name: 'radio-genres-genre', params: { genre: 'Music' } },
    icon: 'solar:music-note-linear',
    iconActive: 'solar:music-note-bold',
  },
  {
    name: 'News',
    route: { name: 'radio-genres-genre', params: { genre: 'News/Talk' } },
    icon: 'solar:document-text-linear',
    iconActive: 'solar:document-text-bold',
  },
  {
    name: 'Podcasts',
    route: { name: 'podcasts' },
    icon: 'solar:microphone-linear',
    iconActive: 'solar:microphone-bold',
  },
  {
    name: 'Bible',
    route: { name: 'bible' },
    icon: 'la:bible',
    iconActive: 'solar:microphone-bold',
  },
  {
    name: 'By Genre',
    route: { name: 'radio-genres' },
    icon: 'solar:library-linear',
    iconActive: 'solar:map-point-bold',
  },
  {
    name: 'By Location',
    route: { name: 'radio-countries' },
    icon: 'solar:map-point-linear',
    iconActive: 'solar:map-point-bold',
  },
  {
    name: 'By Language',
    route: { name: 'radio-languages' },
    icon: 'solar:global-linear',
    iconActive: 'solar:global-bold',
  },
]

const bottomLinks = [
  {
    name: 'Download app',
    route: oneLinkUrl(),
    icon: 'solar:download-minimalistic-linear',
  },
  {
    name: 'Log in',
    route: '/login/',
    icon: 'solar:login-linear',
  },
  {
    name: 'Sign up',
    route: '/signup/',
    icon: 'solar:user-plus-broken',
  },
]
</script>

<template>
  <nav class="main-nav flex flex-col h-full justify-between overflow-y-auto">
    <div>
      <ul>
        <li v-for="link in mainLinks" :key="link.name">
          <MainNavigationLink :name="link.name" :route="link.route" :icon="link.icon" :icon-active="link.iconActive" />
        </li>
      </ul>

      <ul>
        <li v-for="link in secondaryLinks" :key="link.name">
          <MainNavigationLink :name="link.name" :route="link.route" :icon="link.icon" :icon-active="link.iconActive" />
        </li>
      </ul>
    </div>

    <ul>
      <li v-for="link in bottomLinks" :key="link.name">
        <MainNavigationLink :name="link.name" :route="link.route" :icon="link.icon" />
      </li>
    </ul>
  </nav>
</template>

<style scoped></style>
